import React from 'react'
import { FaCircle } from 'react-icons/fa'

const ProjectShimmerCard = () => {
  return (
      <aside className="flex flex-wrap justify-center pb-2 lg:pb-16 animate-pulse">
        <div className='bg-slate-700 w-80 md:w-96 h-96 m-5 text-neutral-500 group ' >
          <img alt="" className='w-[100%] h-[65%] bg-slate-500 -rotate-6 relative blur-3xl'/>
          <p className='px-5 pt-5 pb-3 ml-5 text-lg bg-slate-500 w-64 h-6 rounded-md'></p>
          <div className='flex items-center justify-between'>
            <div className='flex flex-wrap px-5 py-1 text-sm'>
                <span className='px-1 bg-slate-500 w-36 h-6 rounded-md'></span>
            </div>
            <div className='flex p-5 justify-end text-slate-500'>
              <FaCircle className='mx-2 text-2xl ' />
              <FaCircle className='mx-2 text-2xl'/>
            </div>
          </div>
        </div>         
      </aside>
  )
}

export default ProjectShimmerCard;