import Navigation from "./Navigation";
import Socials from "./Socials";
import { AUTHOR_NAME, AUTHOR_NICKNAME, AUTHOR_SURNAME } from "../constants";
import { useEffect, useState } from "react";
import SelectLang from "./SelectLang";

const Header = () => {
    const [boxShadow, setBoxShadow] = useState('none');
    
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const threshold = 50;
            if (scrollPosition > threshold) {
              setBoxShadow('0 10px 10px rgba(0, 0, 0, 1)');
            } else {
              setBoxShadow('none');
            }
          };
          window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="fixed w-full pt-11 tertiary-color z-40 hidden md:block " style={{ boxShadow }}>
             <section className="flex justify-between text-2xl fourthary-color h-20 backdrop-blur">
            <h1 className="p-5 pl-8 lg:pl-28 text-white fonts content-center">{AUTHOR_NAME} 
                <span className=" pl-2 pr-2 text-green">
                    {AUTHOR_NICKNAME}
                </span> 
                {AUTHOR_SURNAME}
            </h1>
            <Navigation />
            <SelectLang />
            <span className="pr-5 lg:pr-28 content-center"> 
                <Socials/>
            </span>
           
        </section>
        </div>
    )
    
    
}

export default Header;