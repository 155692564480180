import zaznamNadcasov from "/src/img/zaznamNadcasov.webp";
import prusy from "/src/img/prusy.webp";
import tictactoe from "/src/img/tictactoe2.webp";
import rockpaperscissors from "/src/img/rockpaperscissors.webp";
import trainapp from "/src/img/trainapp.webp";
import passgen from "/src/img/passgen.webp";
import kvet from "/src/img/DSC_0172_webp.webp";
import osa from "/src/img/DSC_0173_webp.webp";
import skalar from "/src/img/DSC_0274_webp.webp";
import mesiac from "/src/img/DSC_0297_webp.webp";
import trainup from "/src/img/trainup.webp"



export const REFERENCES = [
   {
        key: "dfgh",
        name: "Matúš Chudiváni",
        company: "Skúška",
        photo: "",
        text: "Zatiaľ nebola pridaná žiadna referencia."
    },
    {
        key: "hgtf",
        name: "Matúš Chudiváni",
        company: "Skúška",
        photo: "",
        text: "Čoskoro snáď nejaká pribudne."
    },
]

export const PROJECTS = [
    {
        key: "ihji",
        name: "TrainUp application",
        technologies: ["Python ","Django " , "Css ", "Javascript "],
        github: "unknown",
        web: "https://lmp.pythonanywhere.com/",
        image: trainup,
    },
    {
        key: "yuoi",
        name: "Password generator",
        technologies: ["Html ", "Css ", "Javascript "],
        github: "unknown",
        web: "https://www.password-generator.codespace.sk/",
        image: passgen,
    },
    {
        key: "yyoi",
        name: "Training organizer",
        technologies: ["Html ", "Css ", "Javascript ","Php ", "Laravel ", "Bootstrap ", "MySql "],
        github: "unknown",
        web: "https://training-organizer.codespace.sk/",
        image: trainapp,
    },
    {
        key: "yuii",
        name: "Prusy web",
        technologies: ["Html ", "Css ", "Javascript ","Php ", "Laravel ", "Bootstrap ", "MySql "],
        github: "unknown",
        web: "https://prusy.codespace.sk/",
        image: prusy,
    },
    {
        key: "yuri",
        name: "Rock-Paper-Scissors",
        technologies: ["Html ", "Css ", "Javascript ",],
        github: "https://github.com/lmpko/rock-paper-scissors-game",
        web: "https://rock-paper-scissors.codespace.sk/",
        image: rockpaperscissors,
    },
    {
        key: "ypoi",
        name: "Tic-Tac-Toe",
        technologies: ["Html ", "Css ", "Javascript ",],
        github: "https://github.com/lmpko/tic-tac-toe-game",
        web: "https://tic-tac-toe.codespace.sk/",
        image: tictactoe,
    },
    {
        key: "yeoi",
        name: "Záznam nadčasov",
        technologies: ["Html ", "Css ", "Javascript ", "Php ", "Bootstrap ", "jQuery ", "MySql"],
        github: "unknown",
        web: "unknown",
        image: zaznamNadcasov,
    } 
]

export const PHOTOS = [
    {
        photo: kvet,
        name: "Kvet",
        key: "jhjh",
    },
    {
        photo: osa,
        name: "Osa",
        key: "uyut",
    },
    {
        photo: skalar,
        name: "Skalár",
        key: "oitr",
    },
    {
        photo: mesiac,
        name: "Mesiac",
        key: "ofgj",
    },

]

export const AUTHOR_NAME = "Matúš";
export const AUTHOR_SURNAME = "Chudiváni"
export const AUTHOR_NICKNAME = "\"LMP\""

export const COPYRIGHT_TEXT = "© 2023. ";

export const STEAM_ID = "https://steamcommunity.com/id/lmpko";
export const GITHUB_ID = "https://github.com/lmpko";
export const LINKEDIN_ID = "https://www.linkedin.com/in/mat%C3%BA%C5%A1-chudiv%C3%A1ni-b35a7a207/";
export const TWITTER_ID = "https://twitter.com/Lmpkos";
export const EMAIL_ID = "lmpkoo@gmail.com";

export const PROFESION_NAME = "Web developer";
export const FIRST_HEADING = "Jazyk je orgánom myslenia";
export const SECOND_HEADING = "Každé myslenie je myslenie v jazyku";

export const INTRODUCTION_FIRST_PARAGRAPH = "Už to, že ideš je dobré, pretože ostať stáť je istá smrť";
export const INTRODUCTION_SECOND_PARAGRAPH = "Všetko to začalo údajne veľkým treskom... Buchol som po stole a povedal si dosť!...";

