function changeGlassColor (activeTechnologies, glassItem){
  const indicatorBox = document.getElementsByClassName('indicator-technology-box');
  activeTechnologies.forEach((activeTechnology) =>{
    if(activeTechnology.classList.contains('item-1') && activeTechnology.classList.contains('active')){
        glassItem[0].style.backgroundColor = '#002eff1c';
        indicatorBox[0].setAttribute('data-text', 'React.js')
    }if(activeTechnology.classList.contains('item-2') && activeTechnology.classList.contains('active')){
        glassItem[0].style.backgroundColor = '#fcff003d'
        indicatorBox[0].setAttribute('data-text', 'Javascript')
    }if(activeTechnology.classList.contains('item-3') && activeTechnology.classList.contains('active')){
        glassItem[0].style.backgroundColor = '#ffc1004d'
        indicatorBox[0].setAttribute('data-text', 'HTML 5')
    }if(activeTechnology.classList.contains('item-4')  && activeTechnology.classList.contains('active')){
        glassItem[0].style.backgroundColor = '#db07ff1c'
        indicatorBox[0].setAttribute('data-text', 'CSS 3')
    }if(activeTechnology.classList.contains('item-5')  && activeTechnology.classList.contains('active')){
        glassItem[0].style.backgroundColor = '#07bdff1c'
        indicatorBox[0].setAttribute('data-text', 'Tailwind css')
    }if(activeTechnology.classList.contains('item-6')  && activeTechnology.classList.contains('active')){
        glassItem[0].style.backgroundColor = '#ffffff1c'
        indicatorBox[0].setAttribute('data-text', 'Git')
    }if(activeTechnology.classList.contains('item-7')  && activeTechnology.classList.contains('active')){
        glassItem[0].style.backgroundColor = '#ff00001c'
        indicatorBox[0].setAttribute('data-text', 'Laravel')
    }
  })
}

export function activeTechnology(){
  const technologyTreeItems = document.querySelectorAll('.technology'); 
  const glass = document.querySelectorAll('.glass');

  technologyTreeItems.forEach((item) =>{
      item.classList.remove('active');
      this.classList.add('active');
      
  });
  changeGlassColor(technologyTreeItems, glass);
}

export function randomClickOnTechnologyTree(technologyTree){
  const randomClickOnTechnologyTree = technologyTree[Math.floor(Math.random() * technologyTree.length)]
  randomClickOnTechnologyTree.click()
}

export  function leftClick(references, referencesPersons){
  references.forEach(function (reference){
    reference.classList.add("hidden")
  })
  references.push(references.shift())
  references.slice(-1).forEach(function (reference){
      reference.classList.remove("hidden")
      reference.animate([
          { marginLeft: '33%' },
          { marginLeft: '0%' },
      ],{duration: 500})
  })

  
  referencesPersons.forEach(function (referencePerson){
    referencePerson.classList.add("hidden")
  })

  referencesPersons.unshift(referencesPersons.pop())
  referencesPersons.slice(-1).forEach(function (referencePerson){
      referencePerson.classList.remove("hidden")
      referencePerson.animate([
        { opacity: '0%' },
        { opacity: '100%' },
      ],{duration: 1000})
  })
  
}

export function rightClick(references, referencesPersons){
  references.forEach(function (reference){
    reference.classList.add("hidden")
  })
  references.push(references.shift())
  references.slice(-1).forEach(function (reference){
      reference.classList.remove("hidden")
      reference.animate([
          { marginLeft: '-33%' },
          { marginLeft: '0%' },
      ],{duration: 500})
  })

  referencesPersons.forEach(function (referencePerson){
    referencePerson.classList.add("hidden")
  })
  referencesPersons.push(referencesPersons.shift())
  referencesPersons.slice(-1).forEach(function (referencePerson){
      referencePerson.classList.remove("hidden")
      referencePerson.animate([
          { opacity: '0%' },
          { opacity: '100%' },
      ],{duration: 1000})
  })
}
