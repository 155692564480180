import React from 'react'
import { VscGithub } from "react-icons/vsc";
import { BsGlobe } from "react-icons/bs";


const Project = (project) => {
  return (
    <div className='secondary-color w-80 h-96 m-5 text-blue group hover:border-2 hover:border-green-700' >
      <div className='w-full h-[65%]  -rotate-6 relative group-hover:rotate-6 z-20'>
        <img src={project?.project?.image} alt={project?.project?.name} className='h-full w-full object-contain' loading="lazy"/>
      </div>
        <p className='px-5 pt-5 pb-3 text-lg group-hover:text-green-500'>{project?.project?.name }</p>
        <div className='flex items-center justify-between'>
          <div className='flex flex-wrap px-5 py-1 text-sm'>
              <span className='px-1'>{project?.project?.technologies}</span>
          </div>
          <div className='flex p-5 justify-end'>
            {project?.project?.github !== "unknown" ? <a href={project?.project?.github} aria-label="Project Github"><VscGithub className='mx-2 text-2xl group-hover:animate-pulse group-hover:text-green-500' /></a> : null }
            {project?.project?.web !== "unknown" ? <a href={project?.project?.web} aria-label="Project Website"><BsGlobe className='mx-2 text-2xl group-hover:animate-pulse group-hover:text-green-500'/></a> : null} 
          </div>
        </div>
        
    </div>
  )
}

export default Project