import React from 'react'
import ProjectShimmerCard from './ProjectShimmerCard'
import SectionName from './SectionName'


const ProjectsShimmer = () => {
  return (
    <section className='tertiary-color' id="projekty">
      <SectionName props={{name: "Projekty", text: "Aj tie nie úplne dokončené",}}/>
      <section className='flex flex-wrap justify-center'>
        <ProjectShimmerCard/>
        <ProjectShimmerCard />
        <ProjectShimmerCard/>
        <ProjectShimmerCard/>
        <ProjectShimmerCard/>
        <ProjectShimmerCard/>
      </section>
    </section>
  )
}

export default ProjectsShimmer;