const IntroductonCard = ({props}) => {
    return (
        <div className="w-full tertiary-color rounded-2xl p-5 my-2 mr-2 hover:bg-green-900 hover:border-l-8 hover:border-green-600">
            <h1 className="pl-5 pb-2 text-green text-5xl">{props.name}</h1>
            <h2 className="pl-5 pb-1 text-white text-xl">{props.text1}</h2>
            <h3 className="pl-5 text-blue text-base">{props.text2}</h3>
        </div>
    )
}

export default IntroductonCard;