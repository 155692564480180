import React from 'react';
import OneReferenceTextArea from './OneReferenceTextArea';
import OneReferencePerson from './OneReferencePerson';
import { REFERENCES } from '../constants';
import ReferenceShimmerCard from './ReferenceShimmerCard';

const OneReference = () => {
  return REFERENCES.length === 0 ? (<ReferenceShimmerCard/>) : (
    <aside className='flex flex-col items-center w-full p-10'>
        <div className='flex w-full justify-center '>
            {REFERENCES.map((reference) => (

              <div className='reference w-full lg:w-[60%] tertiary-color p-5 pl-10 pr-10 rounded-2xl refText  group' key={reference?.key}>
                <OneReferenceTextArea props={reference} />
              </div>
           
            ))}
        </div>
        
        <OneReferencePerson props={REFERENCES}/>
  
    </aside>
  )
}

export default OneReference