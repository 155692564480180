import { useSelector } from "react-redux";
import { LANG } from "../utils/langConstant";

const Numbers = () => {
    const lang = useSelector((state) => state.app.lang);
    const data = LANG[lang] || LANG["sk"];
    return (
        <aside className="flex pl-8 sm:pl-28 pb-28 pt-28 text-white">
            <div className="flex p-2 pl-0" >
                <span className="text-6xl">4</span>
                <span className="flex flex-col p-2">
                    <span>{data.years_text1}</span>
                    <span>{data.years_text2}</span> 
                </span>
            </div>
            <div className="flex p-2">
                <span className="text-6xl">7</span>
                <span className="flex flex-col p-2">
                    <span>{data.projects_text1}</span>
                    <span>{data.projects_text2}</span> 
                </span>
            </div>
        </aside>
    )
}

export default Numbers;