import React, { useEffect } from 'react';
import { RiArrowUpCircleFill } from "react-icons/ri";

const BackToTop = () => {

  useEffect(() => {
    const backToTopButton = document.getElementById('backToTop');
    const scrollHandler = () => {
      const top = window.scrollY;
      if (top < 500) {
        backToTopButton.style.display = 'none';
      } else {
        backToTopButton.style.display = 'block';
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const scrollToTop = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button
      id="backToTop"
      onClick={scrollToTop}
      className='hidden fixed bottom-8 right-8 z-50 text-7xl text-[#2d995b] hover:text-[#27834f] hover:animate-bounce'
      aria-label="Back to top"
    >
      <RiArrowUpCircleFill />
    </button>
  );
};

export default BackToTop;
