import OneReference from "./OneReference";
import SectionName from "./SectionName";
import { useSelector } from "react-redux";
import { LANG } from "../utils/langConstant";

const References = () => {
    const lang = useSelector((state) => state.app.lang);
    const data = LANG[lang] || LANG["sk"];
    return (
        <section className="secondary-color" id="Referencie">
            <SectionName props={{name: data.menu.text3, text: data.menu.subText3,}}/>
            <OneReference />
        </section>
    )
}

export default References;