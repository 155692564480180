import { AUTHOR_NAME, AUTHOR_SURNAME, INTRODUCTION_FIRST_PARAGRAPH, INTRODUCTION_SECOND_PARAGRAPH } from "../constants";
import IntroductonCard from "./IntroductionCard";
import { useSelector } from "react-redux";
import { LANG } from "../utils/langConstant";

const Introduction = () => {
    const lang = useSelector((state) => state.app.lang);
    const data = LANG[lang] || LANG["sk"];
    return (
        <section className="secondary-color flex flex-col-reverse md:flex-row justify-between p-8 sm:p-28 ">
            <aside>
                <IntroductonCard props={{name: "Front-end", text1: data.introduction.card1.text1, text2: data.introduction.card1.text2,}}/>
                <IntroductonCard props={{name: "Back-end" ,text1: data.introduction.card2.text1, text2: data.introduction.card2.text2}}/>
                <IntroductonCard props={{name: "Full-stack" ,text1: data.introduction.card3.text1, text2: data.introduction.card3.text2}}/>
            </aside>
            <aside className="w-full md:w-1/2 md:px-9 pb-10 md:pb-0">
                <p className="text-blue text-lg pb-4">{data.introduction.title}</p>
                <h1 className="text-white text-6xl pb-5">{data.introduction.greeting} {AUTHOR_NAME} {AUTHOR_SURNAME}</h1>
                <p className="text-green text-4xl pb-4"> {data.introduction.text1}</p>
                <p className="text-white text-2xl pb-4">{data.introduction.text2}</p>
            </aside>
        </section>
    )
}

export default Introduction;