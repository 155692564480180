import { FaHome, FaProjectDiagram } from "react-icons/fa";
import { BsChatDotsFill } from "react-icons/bs";
import { MdPhotoCamera } from "react-icons/md";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import { LANG } from "../utils/langConstant";

const Navigation = () => {
    const lang = useSelector((state) => state.app.lang);
    const data = LANG[lang] || LANG["sk"];
    useEffect(() => {
        const sections = [
            { id: '#Home', index: 0 },
            { id: '#Projekty', index: 1 },
            { id: '#Referencie', index: 2 },
            { id: '#Galeria', index: 3 },
        ];
          
        const list = document.querySelectorAll('.list');
          
        const scrollInWieport =  window.addEventListener('scroll', () => {
            // Iterate over the sections and check if they are in the midlle of viewport
            sections.forEach(section => {
              const element = document.querySelector(section.id);
              const isInViewport = element.getBoundingClientRect().top < window.innerHeight / 2 && element.getBoundingClientRect().bottom >= window.innerHeight / 2;
          
              // If the section is in the viewport, add a class to its corresponding list item
                if (isInViewport) {
                    list[section.index].classList.add('active');
          
                    // Remove the active class from the other list items
                    for (let i = 0; i < list.length; i++) {
                        if (i !== section.index) {
                            list[i].classList.remove('active');
                        }
                    }
                }
            });
        });

        return () => {
            window.removeEventListener('scroll', scrollInWieport);
        };
    }, []);
   

    return (<>
        <ul className="flex text-lg text-white">
            <li className="p-5 z-10 list active">
                <a href="#" className="flex flex-col items-center">
                    <span className="icon">
                        <FaHome className="w-8 h-6" />
                    </span>
                    <span className="icon-text active">
                        {data.menu.text1}
                    </span>
                </a>
            </li>
            <li className="p-5 z-10 list">
                <a href="#Projekty" className="flex flex-col items-center">
                    <span className="icon">
                        <FaProjectDiagram className="w-8 h-6" />
                    </span>
                    <span className="icon-text">
                        {data.menu.text2}
                    </span>
                </a>
            </li>
            <li className="p-5 z-10 list">
                <a href="#Referencie" className="flex flex-col items-center">
                    <span className="icon">
                        <BsChatDotsFill className="w-8 h-6" />
                    </span>
                    <span className="icon-text">
                        {data.menu.text3}
                    </span>
                </a>
            </li>
            <li className="p-5 z-10 list">
                <a href="#Galeria" className="flex flex-col items-center">
                    <span className="icon">
                        <MdPhotoCamera className="w-8 h-6" />
                    </span>
                    <span className="icon-text">
                        {data.menu.text4}
                    </span>
                </a>
                
            </li>
            <li className="indicator"></li>
        </ul>
        
        </>
    )
}

export default Navigation;