import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import BackToTop from "./components/BackToTop";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import HamburgerMenu from "./components/HamburgerMenu";
import Introduction from "./components/Introduction";
import Projects from "./components/Projects";
import References from "./components/References";
import Sidemenu from "./components/Sidemenu";
import Header from "./components/Header"
import Home from "./components/Home";
import { Provider } from "react-redux";
import store from './utils/Store';

const AppLayout = () => { 

    useEffect(() => {

        if (navigator.serviceWorker) {
            navigator.serviceWorker.register(
                new URL('/sw.js', import.meta.url),
                {
                    type: 'module',
                    scope: "/",
                }
            ).then(res => {
                console.log("Service Worker registered");
            })
            .catch(err => {
                console.error("Service Worker registration failed", err);
            });
        }
       
        window.history.pushState(null, '', '/');
        return () => {
            cleanup
        };
    }, []);
    return (
        <>
            <Provider store={store}>
                <Header />
                <HamburgerMenu/>
                <Sidemenu />
                <section id="Home">
                    <Home />
                    <Introduction />
                </section>
                <Projects />
                <References />
                <Gallery />
                <Footer />
                <BackToTop/>
            </Provider>
            
        </>
    )
}

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(<AppLayout/>)