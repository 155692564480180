import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const ReferenceShimmerCard = () => {
  return (
    <aside className='flex flex-col items-center p-4 md:p-16 pt-0 animate-pulse'>
    <div className='flex w-full justify-center '>
          <div className='reference w-full md:w-[60%] h-40 bg-slate-700 p-5 pl-10 pr-10 rounded-2xl' >
            <div className='w-[60%] h-10 bg-slate-500 rounded-md pt-20 mt-5'>
                
            </div>
          </div> 
    </div>
        <div className='flex items-center justify-between w-full md:w-[55%] pt-3'>
        <IoIosArrowBack id="left-arrow" className='text-5xl text-slate-700'/>
            <div className='flex items-center'>
                <div className='w-24 h-24 bg-slate-700 rounded-md'></div>
                <div className='flex flex-col text-white pl-2' >
                    <p className='text-xl w-40 lg:w-80 h-8 bg-slate-700 mb-2 rounded-md'></p>
                    <p className='w-40 h-8 bg-slate-700 rounded-md'></p>
                </div>
            </div>
            <IoIosArrowForward  id="right-arrow" className='text-5xl text-slate-700'/>
            
        </div>
    </aside>
  )
}

export default ReferenceShimmerCard