import React from 'react'
import { PHOTOS } from '../constants'
import PhotoShimmer from './PhotoShimmer'

const Photo = () => {
  return PHOTOS.length === 0 ? (<PhotoShimmer/>) : (
  <div className="flex flex-wrap justify-center px-8 lg:px-16 pb-16 pt-0" >
    {PHOTOS.map((photo)=> (
      <figure key={photo?.key} className=' text-lg text-white relative w-full md:w-96 h-auto m-0 p-[2px] inline-block scale-[99%] hover:scale-[101%] group grayscale hover:grayscale-0'>
        <img src={photo?.photo} alt={photo?.name} width="640" height="360" loading="lazy"/>
          <figcaption className='absolute bottom-4 bg-neutral-900 text-white font-bold 
              opacity-50 pb-1 w-full h-8 text-center group-hover:text-green-400 '>{photo?.name}</figcaption>
      </figure>
    ))}
    
  </div>
  )
}

export default Photo