import React from 'react'
import {GiHamburgerMenu} from 'react-icons/gi'
import { toggleMenu } from '../utils/AppSlice'
import { useDispatch } from 'react-redux'
import SelectLang from './SelectLang'

const HamburgerMenu = () => {

  const dispatch = useDispatch()
  const toggleMenuHandler = () => {
    dispatch(toggleMenu());
  };

  return (
    <div className='fixed top-0 z-50 w-full h-20 bg-[#011c289a] p-6 text-3xl text-white md:hidden'>
      <div className='flex'>
        <button className=' hover:text-[#2d995b] mr-2' aria-label="menu button" onClick={() => toggleMenuHandler()}>
          <GiHamburgerMenu/>
        </button>   
        <SelectLang />
      </div>
    </div>
  )
}

export default HamburgerMenu