export const LANG = {
    sk:{
        menu:{
            text1: "Domov",
            text2: "Projekty",
            subText2: "Aj tie nie úplne dokončené",
            text3: "Referencie",
            subText3: "Čo o mne hovoríte Vy",
            text4: "Galéria",
            subText4: "Moje amatérske fotky",
        },
        FIRST_HEADING : "Jazyk je orgánom myslenia",
        SECOND_HEADING :"Každé myslenie je myslenie v jazyku",
        years_text1: "roky",
        years_text2: "samoštúdia",
        projects_text1: "dokončených",
        projects_text2: "projektov",
        introduction:{
            title: "Predstavenie",
            greeting: "Ahoj! Ja som",
            text1: "Už to, že ideš je dobré, pretože ostať stáť je istá smrť",
            text2: "Všetko to začalo údajne veľkým treskom... Buchol som po stole a povedal si dosť!...",
            card1:{
                text1: "Nech to nejako vyzerá",
                text2: "Spoj ma s back-endom",
            },
            card2:{
                text1: "Nech to nejako funguje",
                text2: "Spoj ma s front-endom",
            },
            card3:{
                text1: "Spojením dvoch nevznikne celok, ale tretie",
                text2: "Hotovo",
            }
        },
        copyright: "Všetky Práva Vyhradené"
    },
    gb:{
        menu:{
            text1: "Home",
            text2: "Projects",
            subText2: "Even those not completely finished",
            text3: "References",
            subText3: "What do you say about me",
            text4: "Gallery",
            subText4: "My amateur photos",
        },
        FIRST_HEADING : "Language is an organ of thought",
        SECOND_HEADING :"All thinking is thinking in language",
        years_text1: "years of",
        years_text2: "self-study",
        projects_text1: "completed",
        projects_text2: "projects",
        introduction:{
            title: "Introduction",
            greeting: "Hello! I'm ",
            text1: "Even the fact that you are going is good, because to stay standing is certain death",
            text2: "It all started with a big bang... I banged on the table and said enough!...",
            card1:{
                text1: "Let it look like something",
                text2: "Connect me to the back-end",
            },
            card2:{
                text1: "Make it work somehow",
                text2: "Connect me to the front-end",
            },
            card3:{
                text1: "Combining two doesn't create a whole, but a third",
                text2: "Done",
            }
        },
        copyright: "All Rights Reserved"
    }
}