import React, { useState } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { toggleLang, selectLang  } from '../utils/AppSlice';
import Select from 'react-select';
import Flag from 'react-world-flags';

const SelectLang = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);

    const handleLangChange = (selectedOption) => {
        dispatch(toggleLang(selectedOption.value)); // Update Redux state with selected language
      };

    const languageOptions = [
        {
          value: 'sk',
          label: (
            <span className="flex items-center cursor-pointer">
              <Flag code="SK" style={{ width: '30px' }} alt='Slovakia flag'/>
            </span>
         
          ),
        },
        {
          value: 'gb',
          label: (
            <span className="flex items-center cursor-pointer">
              <Flag code="GB" style={{  width: '30px' }} alt='Great Britain flag'/>
            </span>
          ),
        },
      ];

    return (
        <span className="pr-2 content-center"> 
            <Select
                value={languageOptions.find((option) => option.value === lang)} // Find the currently selected language
                onChange={handleLangChange}
                options={languageOptions}
                aria-label="Choose language"
                className="font-bold text-lg p-2 "
                isSearchable={false}
                styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minHeight: '40px',
                      background: "transparent",
                      borderRadius: "0",
                      boxShadow:  state.isFocused ? "rgb(0 187 223) 0px 0px 0px 1px" : "none",
                      border: "none"
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? '#2d995b' : state.isFocused ? '#00bbdf' : '#e5e7eb',
                      padding: '10px',
                      cursor: 'pointer',
                      
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: '#e5e7eb',
                      margin: '0',
                      borderRadius: '',
                      boxShadow: '',
                      width: "88px",
                      border: "1px solid #00bbdf"
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: '#e5e7eb',
                      ':hover': {
                        color: '#2d995b',
                      },
                      cursor: 'pointer'
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        display: 'none',
                      }),
                    menuList: (provided) => ({
                        ...provided,
                        padding: '0',
                    }),
                  }}
            />
        </span>
    );
};

export default SelectLang;
