import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: "app",
    initialState: {
        isMenuOpen: false,
        lang: "sk"
    },
    reducers: {
        toggleMenu: (state) => {
            state.isMenuOpen = !state.isMenuOpen;
        }, 
        toggleLang: (state, action) => {
            state.lang = action.payload;
        },
    }
})

export const selectLang = (state) => state.app.lang;
export const { toggleMenu, toggleLang } = appSlice.actions;
export default appSlice.reducer;