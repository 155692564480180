import { EMAIL_ID, GITHUB_ID, LINKEDIN_ID, STEAM_ID, TWITTER_ID } from "../constants";
import {FaGithub, FaSteam, FaTwitter, FaLinkedin}  from 'react-icons/fa';
import {MdMail} from "react-icons/md";

const Socials = () => {
    return (
         <ul className="flex text-white">
            <li className="py-5 px-2 hover:scale-95 hover:text-[#2d995b]" ><a href={"mailto:" + EMAIL_ID} aria-label="Email"><MdMail /></a></li>
            <li className="py-5 px-2 hover:scale-95  hover:text-[#2d995b]" ><a href={LINKEDIN_ID} aria-label="Linkedin"><FaLinkedin /></a></li>
            <li className="py-5 px-2 hover:scale-95  hover:text-[#2d995b]" ><a href={GITHUB_ID} aria-label="Github"><FaGithub /></a></li>
            
        </ul>
    )
}

export default Socials;