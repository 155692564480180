import Project from "./Project";
import SectionName from "./SectionName";
import { PROJECTS } from "../constants.js";
import ProjectsShimmer from "./ProjectsShimmer";
import { useSelector } from "react-redux";
import { LANG } from "../utils/langConstant";

const Projects = () => {
    const lang = useSelector((state) => state.app.lang);
    const data = LANG[lang] || LANG["sk"];
    return PROJECTS.length === 0 ? (<ProjectsShimmer/>) : (
        <section className="tertiary-color" id="Projekty">
            <SectionName props={{name: data.menu.text2, text: data.menu.subText2,}}/>
            <aside className="flex flex-wrap justify-center pb-16">
                {PROJECTS.map((project)=>(
                         <Project project={project} key={project?.key}/>
                ))}
                
            </aside>
           
        </section>
    )
}

export default Projects;