import React from 'react';
import {FaQuoteLeft, FaQuoteRight}  from 'react-icons/fa';

const OneReferenceTextArea = (props) => {
  return (
    <>
        <FaQuoteLeft className='text-blue text-5xl group-hover:text-[#2d995b]'/>
        <p className='text-white p-5 pl-1'>{props.props.text}</p>
        <FaQuoteRight className='text-blue text-5xl float-right group-hover:text-[#2d995b]'/>
    </>
  )
}

export default OneReferenceTextArea