import Photo from "./Photo";
import SectionName from "./SectionName";
import { useSelector } from "react-redux";
import { LANG } from "../utils/langConstant";

const Gallery = () => {
    const data = LANG[useSelector((state) => state.app.lang)];
    return  (
        <section className="tertiary-color" id="Galeria">
            <SectionName props={{name: data.menu.text4, text: data.menu.subText4,}}/>
            <Photo />
        </section>
    )
}

export default Gallery;