import React from 'react';
import { useEffect } from 'react';
import {SiReact, SiJavascript, SiHtml5, SiCss3,SiGit, SiTailwindcss,SiLaravel} from 'react-icons/si';
import { activeTechnology, randomClickOnTechnologyTree } from '../functions';



function TechnologyThree() {

  useEffect(() => {
    const technologyTreeItems = document.querySelectorAll('.technology'); 
    const glass = document.querySelectorAll('.glass');
    glass[0].style.backgroundColor = '#002eff1c';

    technologyTreeItems.forEach((item) =>{
        item.addEventListener('click', activeTechnology);
    })

    randomClickOnTechnologyTree(technologyTreeItems)

    const technologyTreeClickInterval = setInterval( function (){
        randomClickOnTechnologyTree(technologyTreeItems)
    }, 3000)
 
    return () => {
        technologyTreeItems.forEach((item) =>{
          item.removeEventListener('click', activeTechnology);
        });
        setTimeout(() => {
              clearInterval(technologyTreeClickInterval);
            }, 3000);
        };
  }, []);

 

  return (
    <aside className='flex items-center'>
        <ul className='menu-icons'> 
       
            <div className='circle-menu-icons' aria-hidden="true"></div>
            <li className='item-1 technology active'><a href="#/" aria-label="React"><SiReact/></a></li>
            <li className='item-2 technology' ><a href="#/" aria-label="Javascript"><SiJavascript/></a></li> 
            <li className='item-3 technology' ><a href="#/" aria-label="Html5"><SiHtml5/></a></li>   
            <li className='item-4 technology' ><a href="#/" aria-label="Css3"><SiCss3/></a></li> 
            <li className='item-5 technology' ><a href="#/" aria-label="Tailwindcss"><SiTailwindcss/></a></li>
            <li className='item-6 technology' ><a href="#/" aria-label="Git"><SiGit/></a></li>
            <li className='item-7 technology' ><a href="#/" aria-label="Laravel"><SiLaravel/></a></li>
           <span className='indicator-technology' role="presentation" aria-hidden="true"></span>
           <span className='indicator-technology-box text-center text-2xl text-white' data-text=" React.js" aria-hidden="true"></span>
        </ul>
         
       
        <div className='glass bg-opacity-50 w-60 h-[420px] backdrop-blur-lg shadow-[0px_0_29px_7px_#090a09] shadow-black'></div>
    </aside>
    
  )
}

export default TechnologyThree