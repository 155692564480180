import React from 'react'
import {IoIosArrowBack, IoIosArrowForward}  from 'react-icons/io';
import {BsPersonFill, }  from 'react-icons/bs';
import { useEffect } from 'react';
import { leftClick , rightClick } from '../functions'

const OneReferencePerson = (props) => {

  useEffect(() => {
    let referencesPersons = document.getElementsByClassName("referencePerson")
    referencesPersons = Array.from(referencesPersons)

    let references = document.getElementsByClassName("reference")
    references = Array.from(references)

    let clicked
    
    const left = document.getElementById("left-arrow")
    const right = document.getElementById("right-arrow")
   
    references.slice(1).forEach(function (reference) {
      reference.classList.add("hidden")
    });
    referencesPersons.slice(1).forEach(function (referencePerson) {
      referencePerson.classList.add("hidden")
    });

    const clickHandlerLeft = function(e) {
      e.preventDefault();
      clicked = 1;
      leftClick(references, referencesPersons);
    };

    const clickHandlerRight = function(e) {
      e.preventDefault();
      clicked = 1;
      rightClick(references, referencesPersons);
    };

    left.addEventListener('click', clickHandlerLeft);
    right.addEventListener('click', clickHandlerRight);

 
    const interval = setInterval(function (){
      if (clicked !== 1) {
          rightClick(references, referencesPersons)
      }else{
          setInterval(function (){
              clicked = 0
          },10000)
      }
     },5000)

    return () => {
      left.removeEventListener('click', clickHandlerLeft);
      right.removeEventListener('click', clickHandlerRight);
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='flex flex-col md:flex-row items-center justify-center md:justify-between w-full lg:w-[55%] pt-3'>
      <IoIosArrowBack id="left-arrow" className='text-5xl text-blue hover:text-[#2d995b] hover:scale-150 hover:animate-pulse cursor-pointer'/>
      <div className='flex items-center'>
        {props.props.map((reference) => (
          <aside className='refName referencePerson flex items-center border-2 rounded-xl border-[#7a797933] z-30' key={reference?.key}>
            <BsPersonFill className='text-7xl text-[#2d995b]'/>
            <div className='flex flex-col text-white pl-2' >
              <p className='sm:text-2xl '>{reference?.name}</p>
              <p className='text-blue'>{reference?.company}</p>
            </div>
          </aside>
        ))}
      </div>
      <IoIosArrowForward  id="right-arrow" className='text-5xl text-blue hover:text-[#2d995b] hover:scale-150 hover:animate-pulse cursor-pointer'/>
    </div>
  )
}

export default OneReferencePerson