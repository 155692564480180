import React from 'react'

const PhotoShimmerCard = () => {
  return (
    <div className='bg-slate-700 w-96 h-56  m-[2px] animate-pulse'>
        <div className='bg-slate-500 w-96 h-10 relative -bottom-36'>
            
        </div>
    </div>
  )
}

export default PhotoShimmerCard