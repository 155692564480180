import { PROFESION_NAME, FIRST_HEADING, SECOND_HEADING } from "../constants";
import Numbers from "./Numbers";
import TechnologyThree from "./TechnologyTree";
import { useSelector } from "react-redux";
import { LANG } from "../utils/langConstant";
import { useEffect, useState } from "react";


const Home = () => {
    const lang = useSelector((state) => state.app.lang);
    const data = LANG[lang] || LANG["sk"];
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        //using this instead of tailwind css becouse with tailwind css code is still in dom and block clicking functionality for sidemenu becouse js doing autoclicking
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 1024); // Update state on resize
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize); // Cleanup on unmount
        };
      }, []);

    return (
         <section  className="pt-36 tertiary-color flex justify-between items-center">
            <aside>
                <h1 className="p-2 pt-5 pl-8 sm:pl-28 text-2xl text-green">{PROFESION_NAME}</h1>
                <h2 className="p-2 pl-8 sm:pl-28 text-5xl text-white">{data.FIRST_HEADING}</h2>
                <h3 className="p-2 pl-8 sm:pl-28 text-3xl text-blue">{data.SECOND_HEADING}</h3>
                <Numbers />
            </aside>
            {isLargeScreen && (
                <aside>
                    <TechnologyThree />
                </aside>
            )}
            
         </section>
    )
   

}

export default Home;