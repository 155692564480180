import React from 'react'
import PhotoShimmerCard from './PhotoShimmerCard'

const PhotoShimmer = () => {
  return (
   
    <div className='secondary-color pb-20'>
        <section className='flex flex-wrap justify-center '>
            <PhotoShimmerCard/>
            <PhotoShimmerCard/>
            <PhotoShimmerCard/>
            <PhotoShimmerCard/>
            <PhotoShimmerCard/>
            <PhotoShimmerCard/>
       </section>
    </div>
   
  )
}

export default PhotoShimmer